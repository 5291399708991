import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListButton } from 'ui/ListButton';
import { ReactComponent as AltArrowRightIcon } from 'assets/icons/line/AltArrowRight.svg';
import { ReactComponent as UserIcon } from 'assets/icons/solid/User.svg';
import { ReactComponent as MailIcon } from 'assets/icons/solid/Mail.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/solid/Key.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/solid/Shield.svg';
import { formatDistanceToNow } from 'date-fns';
import { SubHeaderBlock } from 'components/SubHeaderBlock';
import { useAuthorizationModalActions } from './hooks/useAuthorizationModalActions';
import styles from './AuthorizationData.module.scss';

interface AuthorizationDataProps {
  username?: string;
  email: string;
  passwordUpdatedAt: string;
}

export const AuthorizationData: FC<AuthorizationDataProps> = ({
  username,
  email,
  passwordUpdatedAt,
}) => {
  const { t } = useTranslation(['common', 'profile']);

  const formattedDate = formatDistanceToNow(new Date(passwordUpdatedAt), {
    addSuffix: true,
  });

  const {
    openModalChangePassword,
    openModalChangeUserName,
    openModalResetTwoFactor,
    openModalChangeEmail,
  } = useAuthorizationModalActions();

  return (
    <>
      <SubHeaderBlock>
        <h2>{t('profile:authorizationData')}</h2>
      </SubHeaderBlock>
      <div className={styles.buttonsContainer}>
        {username && (
          <ListButton
            as="button"
            onClick={openModalChangeUserName}
            icon={UserIcon}
            title={t('profile:userName')}
            subTitle={`@${username}`}
            rightBlock={<AltArrowRightIcon className={styles.arrowIcon} />}
          />
        )}
        <ListButton
          as="button"
          onClick={openModalChangeEmail}
          icon={MailIcon}
          title={t('email')}
          subTitle={email}
          rightBlock={<AltArrowRightIcon className={styles.arrowIcon} />}
        />
        <ListButton
          as="button"
          onClick={openModalChangePassword}
          icon={KeyIcon}
          title={t('password')}
          subTitle={`${t('profile:updated')} ${formattedDate}`}
          rightBlock={<AltArrowRightIcon className={styles.arrowIcon} />}
        />
        <ListButton
          as="button"
          onClick={openModalResetTwoFactor}
          icon={ShieldIcon}
          title={t('profile:twoFA')}
          subTitle={t('profile:connected')}
          rightBlock={<AltArrowRightIcon className={styles.arrowIcon} />}
        />
      </div>
    </>
  );
};
