import i18n from 'i18next';
import { defaultLanguageCode } from 'const';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_NS, resources } from './const';

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NS;
    resources: (typeof resources)[typeof defaultLanguageCode];
  }
}

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguageCode,
    defaultNS: DEFAULT_NS,
    interpolation: {
      escapeValue: false,
    },
  });
};
