import { useTranslation } from 'react-i18next';
import { ContactType } from '../types/generated/gql';
import { SelectOption } from '../ui/formItems';

export const useContactTypeOptions = (): Array<SelectOption> => {
  const { t } = useTranslation();

  const mapContactTypeToLabel: Record<ContactType, string> = {
    [ContactType.Skype]: t('messengers.skype'),
    [ContactType.Telegram]: t('messengers.telegram'),
    [ContactType.Whatsapp]: t('messengers.whatsapp'),
    [ContactType.Other]: t('messengers.other'),
  };

  return [
    {
      label: mapContactTypeToLabel[ContactType.Skype],
      value: ContactType.Skype,
    },
    {
      label: mapContactTypeToLabel[ContactType.Telegram],
      value: ContactType.Telegram,
    },
    {
      label: mapContactTypeToLabel[ContactType.Whatsapp],
      value: ContactType.Whatsapp,
    },
    {
      label: mapContactTypeToLabel[ContactType.Other],
      value: ContactType.Other,
    },
  ];
};
