import { useLanguage } from 'contexts/LanguageContext';
import { checkIsLanguagePath } from 'utils/language/checkIsLanguagePath';
import { mapLanguagePathToLanguage } from 'utils/language/mapLanguagePathToLanguage';
import { mapLanguageToIntLanguageTag } from 'utils/language/mapLanguageToIntLanguageTag';

export const useMapLangStringToIntLanguageTag = (lang?: string) => {
  const { currentLanguage } = useLanguage();

  return mapLanguageToIntLanguageTag[
    checkIsLanguagePath(lang)
      ? mapLanguagePathToLanguage(lang)
      : currentLanguage
  ];
};
