import { useState } from 'react';
import { DatePickerHandleProps, DatePickerRange } from 'ui/DatePicker';
import { useTranslation } from 'react-i18next';
import {
  getDateTitle,
  getRangeAllTime,
  getRangeTwoLastWeek,
  getRangeLastMonth,
  getRangeLastWeek,
} from '../helpers';
import { DateRanges } from '../const';
import { normalizeDateRange } from '../helpers/normalizeDateRange';

interface UseDatePickerWithRange {
  minDateInitial: Date | null | undefined;
  startDateInitial: Date | null | undefined;
  endDateInitial: Date | null | undefined;
  onDateSubmit: (date: DatePickerRange) => void;
}

export const useDatePickerWithRange = ({
  minDateInitial,
  startDateInitial,
  endDateInitial,
  onDateSubmit,
}: UseDatePickerWithRange) => {
  const [activeCustomRange, setActiveCustomRange] = useState<DateRanges | null>(
    null
  );

  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(startDateInitial || null);
  const [endDate, setEndDate] = useState(endDateInitial || null);

  const handleSubmit = (dates: DatePickerHandleProps) => {
    if (!Array.isArray(dates)) {
      return;
    }

    const normalizedDateRange = normalizeDateRange(dates);
    const [startDateSubmit, endDateSubmit] = normalizedDateRange;

    if (startDateSubmit && endDateSubmit) {
      onDateSubmit(normalizedDateRange);
    }

    if (!startDateSubmit && !endDateSubmit) {
      onDateSubmit(dates);
      setActiveCustomRange(null);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleChange = (dates: DatePickerHandleProps) => {
    setActiveCustomRange(null);
    if (Array.isArray(dates)) {
      const [startDateSubmit, endDateSubmit] = dates;

      setStartDate(startDateSubmit);
      setEndDate(endDateSubmit);
    }
  };

  const setDateAllTime = () => {
    if (activeCustomRange !== DateRanges.AllTime) {
      const { startRange, endRange } = getRangeAllTime(minDateInitial);

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.AllTime);
  };

  const setDateLastWeek = () => {
    if (activeCustomRange !== DateRanges.LastWeek) {
      const { startRange, endRange } = getRangeLastWeek();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastWeek);
  };

  const setDateTwoLastWeek = () => {
    if (activeCustomRange !== DateRanges.TwoLastWeek) {
      const { startRange, endRange } = getRangeTwoLastWeek();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.TwoLastWeek);
  };

  const setDateLastMonth = () => {
    if (activeCustomRange !== DateRanges.LastMonth) {
      const { startRange, endRange } = getRangeLastMonth();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastMonth);
  };

  const customRanges = [
    {
      onClick: setDateAllTime,
      id: DateRanges.AllTime,
      title: t('datePicker.ranges.allTime'),
    },
    {
      onClick: setDateLastMonth,
      id: DateRanges.LastMonth,
      title: t('datePicker.ranges.lastMonth'),
    },
    {
      onClick: setDateTwoLastWeek,
      id: DateRanges.TwoLastWeek,
      title: t('datePicker.ranges.twoLastWeek'),
    },
    {
      onClick: setDateLastWeek,
      id: DateRanges.LastWeek,
      title: t('datePicker.ranges.lastWeek'),
    },
  ];

  const dateTitle = getDateTitle({
    startDate,
    endDate,
  });

  return {
    customRanges,
    activeCustomRange,
    startDate,
    endDate,
    handleSubmit,
    handleChange,
    dateTitle,
  };
};
