import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NonNullableDateRange } from 'types';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { RevShareReferralLinkStatisticInfoData } from '../query/fragments/generated/RevShareReferralLinkStatisticInfoData';
import { useGetRevShareStatisticDetailedTotalQuery } from './useGetRevShareStatisticDetailedTotalQuery';

type GetSummaryRowData = (
  info: RevShareReferralLinkStatisticInfoData
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = ({
  depositsAll,
  withdrawalsAll,
  depositsFee,
  withdrawalsFee,
  adminFee,
  averageDeposit,
  redepositsAll,
  numberOfRedeposits,
}) => {
  const result: Array<ReactNode> = [
    getMoneyAmount(depositsAll),
    getMoneyAmount(withdrawalsAll),
    getMoneyAmount(depositsFee),
    getMoneyAmount(withdrawalsFee),
    getMoneyAmount(adminFee),
    getMoneyAmount(averageDeposit),
    getMoneyAmount(redepositsAll),
    numberOfRedeposits,
  ];

  return result;
};

interface UseGenerateRevShareStatisticDetailedSummaryRowParams {
  revShareMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const useGenerateRevShareStatisticDetailedSummaryRow = ({
  revShareMediaItemId,
  nonNullableDateRange,
}: UseGenerateRevShareStatisticDetailedSummaryRowParams) => {
  const { t } = useTranslation('statistic');

  const { statisticTotal, loading, error } =
    useGetRevShareStatisticDetailedTotalQuery({
      revShareMediaItemId,
      nonNullableDateRange,
    });

  const summaryRowData = statisticTotal
    ? [t('table.allReferrals'), undefined, ...getSummaryRowData(statisticTotal)]
    : undefined;

  return { summaryRowData, loading, error };
};
