import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevShareReferralLinkStatisticData } from '../fragments/generated/RevShareReferralLinkStatisticData';
import { RevShareReferralLinkStatisticConnectionData } from '../fragments/generated/RevShareReferralLinkStatisticConnectionData';
import { RevShareReferralLinkStatisticItemData } from '../fragments/generated/RevShareReferralLinkStatisticItemData';
import { RevShareReferralLinkStatisticInfoData } from '../fragments/generated/RevShareReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetRevShareStatisticDetailedVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  end: SchemaTypes.Scalars['Date']['input'];
  first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  revShareMediaItemId: SchemaTypes.Scalars['ID']['input'];
  start: SchemaTypes.Scalars['Date']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetRevShareStatisticDetailed = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        mediaItem: {
          __typename: 'ReferralLinkMediaItem';
          link: string;
          id: string;
          revShareStatistic: {
            __typename: 'RevShareReferralLinkStatistic';
            statisticItems: {
              __typename: 'RevShareReferralLinkStatisticConnection';
              edges: Array<{
                __typename: 'RevShareReferralLinkStatisticEdge';
                cursor: string;
                node: {
                  __typename: 'RevShareReferralLinkStatisticItem';
                  id: string;
                  playerName?: string | null;
                  statisticInfo: {
                    __typename: 'RevShareReferralLinkStatisticInfo';
                    numberOfRedeposits: number;
                    wasFD: boolean;
                    adminFee: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    averageDeposit: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    depositsAll: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    depositsFee: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    redepositsAll: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    withdrawalsAll: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    withdrawalsFee: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                  };
                };
              }>;
              pageInfo: {
                __typename: 'PageInfo';
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
                startCursor: string;
              };
            };
          };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        mediaItem: {
          __typename: 'ReferralLinkMediaItem';
          link: string;
          id: string;
          revShareStatistic: {
            __typename: 'RevShareReferralLinkStatistic';
            statisticItems: {
              __typename: 'RevShareReferralLinkStatisticConnection';
              edges: Array<{
                __typename: 'RevShareReferralLinkStatisticEdge';
                cursor: string;
                node: {
                  __typename: 'RevShareReferralLinkStatisticItem';
                  id: string;
                  playerName?: string | null;
                  statisticInfo: {
                    __typename: 'RevShareReferralLinkStatisticInfo';
                    numberOfRedeposits: number;
                    wasFD: boolean;
                    adminFee: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    averageDeposit: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    depositsAll: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    depositsFee: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    redepositsAll: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    withdrawalsAll: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    withdrawalsFee: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                  };
                };
              }>;
              pageInfo: {
                __typename: 'PageInfo';
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
                startCursor: string;
              };
            };
          };
        };
      };
};

export const GetRevShareStatisticDetailedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRevShareStatisticDetailed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revShareMediaItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revShareMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RevShareReferralLinkStatisticData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revShareMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RevShareReferralLinkStatisticData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...RevShareReferralLinkStatisticData.definitions,
    ...RevShareReferralLinkStatisticConnectionData.definitions,
    ...RevShareReferralLinkStatisticItemData.definitions,
    ...RevShareReferralLinkStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetRevShareStatisticDetailed__
 *
 * To run a query within a React component, call `useGetRevShareStatisticDetailed` and pass it any options that fit your needs.
 * When your component renders, `useGetRevShareStatisticDetailed` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevShareStatisticDetailed({
 *   variables: {
 *      after: // value for 'after'
 *      end: // value for 'end'
 *      first: // value for 'first'
 *      revShareMediaItemId: // value for 'revShareMediaItemId'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRevShareStatisticDetailed(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRevShareStatisticDetailed,
    GetRevShareStatisticDetailedVariables
  > &
    (
      | { variables: GetRevShareStatisticDetailedVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetRevShareStatisticDetailed,
    GetRevShareStatisticDetailedVariables
  >(GetRevShareStatisticDetailedDocument, options);
}
export function useGetRevShareStatisticDetailedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRevShareStatisticDetailed,
    GetRevShareStatisticDetailedVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetRevShareStatisticDetailed,
    GetRevShareStatisticDetailedVariables
  >(GetRevShareStatisticDetailedDocument, options);
}
export function useGetRevShareStatisticDetailedSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetRevShareStatisticDetailed,
    GetRevShareStatisticDetailedVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetRevShareStatisticDetailed,
    GetRevShareStatisticDetailedVariables
  >(GetRevShareStatisticDetailedDocument, options);
}
export type GetRevShareStatisticDetailedHookResult = ReturnType<
  typeof useGetRevShareStatisticDetailed
>;
export type GetRevShareStatisticDetailedLazyQueryHookResult = ReturnType<
  typeof useGetRevShareStatisticDetailedLazyQuery
>;
export type GetRevShareStatisticDetailedSuspenseQueryHookResult = ReturnType<
  typeof useGetRevShareStatisticDetailedSuspenseQuery
>;
export type GetRevShareStatisticDetailedQueryResult = Apollo.QueryResult<
  GetRevShareStatisticDetailed,
  GetRevShareStatisticDetailedVariables
>;
