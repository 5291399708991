import { useTranslation } from 'react-i18next';
import { ReactComponent as LinkArrowIcon } from 'assets/icons/line/LinkArrow.svg';
import { CardWithIcon } from 'ui/CardWithIcon';
import { useWithdrawRewardModal } from './hooks/useWithdrawRewardModal';

interface WithdrawRewardCardProps {
  className?: string;
}

export const WithdrawRewardCard = ({ className }: WithdrawRewardCardProps) => {
  const { t } = useTranslation('payments');
  const { openWithdrawRewardModal } = useWithdrawRewardModal();

  return (
    <CardWithIcon
      className={className}
      title={t('withdrawRewardTitle')}
      subTitle={t('withdrawRewardSubtitle')}
      onClick={openWithdrawRewardModal}
      icon={LinkArrowIcon}
    />
  );
};
