import { useCallback } from 'react';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { checkIsApolloErrorHasErrorCode } from 'utils/checkIsApolloErrorHasErrorCode';
import { ErrorCode } from 'const';
import {
  GameAccountWithdrawalMethodStatus,
  ListAllOperations,
} from 'types/generated/gql';
import { goBackModal, useModalContext } from 'ui/Modal';
import { useCreateGameAccountLink } from '../queries/generated/CreateGameAccountLink';
import { useGameAccountModal } from '../../WithdrawRewardModal/hooks/useGameAccountModal';

export const useCreateGameAccountLinkMutation = () => {
  const { t } = useTranslation(['common', 'payments']);
  const { user } = useAuth();
  const { dispatch } = useModalContext();
  const { openGameAccountModal } = useGameAccountModal();

  const [createGameAccountLinkMutation, { loading }] = useCreateGameAccountLink(
    { refetchQueries: [ListAllOperations.Query.GetWithdrawalMethods] }
  );

  const handleCompleted = useCallback(
    (accountNumber: string) => {
      dispatch(goBackModal());

      openGameAccountModal({
        status: GameAccountWithdrawalMethodStatus.Pending,
        accountNumber,
      });

      toast.success({
        header: t('payments:linkGameAccountModal.alertSuccessMessageText'),
      });
    },
    [dispatch, openGameAccountModal, t]
  );

  const handleError = useCallback(
    (err: ApolloError) => {
      if (checkIsApolloErrorHasErrorCode(err, ErrorCode.NotFound)) {
        return toast.error({
          header: t('payments:linkGameAccountModal.alertErrorNotFound'),
        });
      }

      if (checkIsApolloErrorHasErrorCode(err, ErrorCode.NotUnique)) {
        return toast.error({
          header: t('payments:linkGameAccountModal.alertErrorNotUnique'),
        });
      }

      return toast.error({
        header: t('common:errors.commonError'),
      });
    },
    [t]
  );

  const createGameAccountLink = useCallback(
    (accountNumber: string) =>
      createGameAccountLinkMutation({
        variables: {
          input: {
            id: user.id,
            accountNumber,
          },
        },
        onError: (error) => handleError(error),
        onCompleted: () => handleCompleted(accountNumber),
      }),
    [handleCompleted, handleError, createGameAccountLinkMutation, user.id]
  );

  return {
    createGameAccountLink,
    loading,
  };
};
