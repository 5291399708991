import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { Button } from 'ui/Button';
import { FieldName } from './const';
import { useCreateGameAccountLinkMutation } from './hooks/useCreateGameAccountLinkMutation';
import { LinkGameAccountValues } from './types';
import { ACCOUNT_NUMBER_MAX_LENGTH } from '../WithdrawRewardModal/const';
import styles from './LinkGameAccountModal.module.scss';

export const LinkGameAccountModal = () => {
  const { t } = useTranslation('payments');

  const { createGameAccountLink, loading } = useCreateGameAccountLinkMutation();

  const handleSubmit = async (values: LinkGameAccountValues) => {
    await createGameAccountLink(values.accountNumber);
  };

  return (
    <>
      <div className={styles.description}>
        <p>{t('linkGameAccountModal.modalText')}</p>
        <p>{t('linkGameAccountModal.additionalModalText')}</p>
      </div>

      <Formik
        initialValues={{
          [FieldName.AccountNumber]: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, dirty }) => (
          <Form>
            <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Medium}>
              <Input
                name={FieldName.AccountNumber}
                label={t('linkGameAccountModal.accountNumber')}
                maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
                disabled={loading}
                onChange={(value) =>
                  setFieldValue(FieldName.AccountNumber, value.toUpperCase())
                }
              />
            </FormBlockWrapper>

            <Button disabled={!dirty} isLoading={loading} type="submit">
              {t('linkGameAccountModal.linkButton')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
