import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Currency } from 'types';
import { FlagIcon } from 'ui/FlagIcon';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { AppRouteParams } from 'utils/tsUtils/extractParamsFromAppRoute';
import { checkIsLanguagePath } from 'utils/language/checkIsLanguagePath';
import { mapLanguageToIntLanguageTag } from 'utils/language/mapLanguageToIntLanguageTag';
import { mapLanguagePathToLanguage } from 'utils/language/mapLanguagePathToLanguage';
import { useGetAvailableCurrenciesQuery } from './queryHooks/useGetAvailableCurrenciesQuery';

export const mapCurrencyToSelectOptions = (
  currencies: Array<Currency>,
  locale?: string
) =>
  currencies.map((currency) => ({
    label: getCurrencyDisplayName(currency, locale),
    value: currency,
    leftElement: <FlagIcon code={currency} />,
    labelRight: getCurrencyNameWithSymbol(currency),
  }));

export const useCurrencySelectOptions = () => {
  const { lang } = useParams<AppRouteParams['Lang']['root']>();

  const locale = checkIsLanguagePath(lang)
    ? mapLanguageToIntLanguageTag[mapLanguagePathToLanguage(lang)]
    : undefined;

  const { availableCurrencies, loading, error } =
    useGetAvailableCurrenciesQuery();

  const currencySelectOptions = useMemo(
    () =>
      availableCurrencies
        ? mapCurrencyToSelectOptions(availableCurrencies, locale)
        : [],
    [availableCurrencies, locale]
  );

  return { currencySelectOptions, loading, error };
};
