import { Dispatch, FC, SetStateAction } from 'react';
import { Form, Formik } from 'formik';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/Button';
import { FieldName } from './const';
import { PasswordResetValues } from './types';
import { validatePasswordReset } from './validation';
import { usePasswordReset } from './hooks/usePasswordReset';
import styles from './PasswordResetForm.module.scss';

const initialValues: PasswordResetValues = {
  [FieldName.Email]: '',
};

interface PasswordResetFormProps {
  setEmailSent: Dispatch<SetStateAction<boolean>>;
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({
  setEmailSent,
}) => {
  const { t } = useTranslation('auth');

  const { passwordReset, loading } = usePasswordReset({
    setEmailSent,
  });

  const handleSubmit = (values: PasswordResetValues) => {
    passwordReset(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validate={validatePasswordReset}
    >
      {({ isValid, dirty }) => (
        <Form
          data-testid="password-reset-form"
          className={styles.form}
          noValidate
        >
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <Input
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              type="email"
              name={FieldName.Email}
              label={t('passwordReset.email')}
              autoComplete="email"
            />
          </FormBlockWrapper>
          <Button
            isFullWidth
            className={styles.submitButton}
            disabled={!isValid || !dirty}
            type="submit"
            isLoading={loading}
          >
            {t('passwordReset.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
