import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize } from 'ui/Button';
import { ReactComponent as UpdateIcon } from 'assets/icons/line/Refresh.svg';
import styles from './ErrorRetry.module.scss';

interface ErrorBlockProps {
  onRefetch: () => void;
}

export const ErrorRetry = ({ onRefetch }: ErrorBlockProps) => {
  const { t } = useTranslation(['payments', 'common']);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    e.stopPropagation();
    onRefetch();
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.errorText}>
        {t('common:errors.failedToLoadData')}
      </span>
      <Button
        iconConfig={{
          iconComponent: UpdateIcon,
          before: true,
        }}
        onClick={handleClick}
        size={ButtonSize.Medium}
        linkStyled
      >
        {t('common:update')}
      </Button>
    </div>
  );
};
