import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  InputPassword,
} from 'ui/formItems';
import { Button } from 'ui/Button';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { FieldName } from './const';
import { PasswordChangeValues } from './types';
import { validatePasswordChange } from './validation';

interface PasswordChangeFormProps {
  setPasswordChanged: Dispatch<SetStateAction<boolean>>;
}

const initialValues: PasswordChangeValues = {
  [FieldName.CreatePassword]: '',
  [FieldName.ConfirmPassword]: '',
};

export const PasswordChangeForm: FC<PasswordChangeFormProps> = ({
  setPasswordChanged,
}) => {
  const { t } = useTranslation('auth');
  const { setPassword } = useAuth();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handleSubmit = (values: PasswordChangeValues) => {
    if (!token) {
      return toast.error({
        header: 'Set password error',
        text: t('errors.passwordSetCommon'),
      });
    }

    return setPassword(values.createPassword, token)
      .then((success) => {
        if (success) {
          setPasswordChanged(true);
        } else {
          toast.error({
            header: 'Set password error',
            text: t('errors.passwordSetCommon'),
          });
        }

        return success;
      })
      .catch((e) => {
        toast.error({
          header: 'Set password error',
          text: e.status === 401 ? t('errors.passwordSet401') : e.detail,
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validatePasswordChange}
    >
      {({ isSubmitting }) => (
        <Form data-testid="password-change-form" noValidate>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <InputPassword
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.CreatePassword}
              label={t('passwordChange.createPassword')}
              autoComplete="new-password"
            />
            <InputPassword
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.ConfirmPassword}
              label={t('passwordChange.confirmPassword')}
              autoComplete="new-password"
            />
          </FormBlockWrapper>
          <Button isFullWidth type="submit" isLoading={isSubmitting}>
            {t('passwordChange.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
