import { FC } from 'react';
import { Falsy } from 'types';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatDate } from 'utils/formatDate';
import { KeyValueGrid, KeyValueGridRow } from 'ui/KeyValueGrid';
import { CopyLink } from 'ui/CopyLink';
import { ReferralLinkMediaItemData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemData';
import { getMediaItemOwnerToShow } from './helpers';

interface MediaDetailProps {
  mediaItem: ReferralLinkMediaItemData;
}

export const MediaDetail: FC<MediaDetailProps> = ({ mediaItem }) => {
  const { name, id, link, createdAt } = mediaItem;
  const { t } = useTranslation(['common', 'media']);

  const mediaItemOwner = getMediaItemOwnerToShow(mediaItem.owner);

  const keyValueGridRows: Array<KeyValueGridRow | Falsy> = [
    {
      title: t('media:mediaName'),
      value: name,
    },
    mediaItemOwner && {
      title: t('media:owner'),
      value: mediaItemOwner,
    },
    { title: t('media:mediaID'), value: id },
    {
      title: t('media:mediaLink'),
      value: (
        <CopyLink target="_blank" to={link} tooltipText={t('linkCopied')}>
          {link}
        </CopyLink>
      ),
    },
    {
      title: t('createdAt'),
      value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutHours),
    },
  ];

  return <KeyValueGrid data={keyValueGridRows} shouldStackOnMd />;
};
