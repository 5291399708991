import { MouseEvent, ReactElement, ReactNode, useRef } from 'react';
import { IconSize } from 'ui/Icon';
import { useTranslation } from 'react-i18next';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { LoadMoreStatus } from 'ui/Table/components/LoadMoreStatus';
import { ApolloError } from '@apollo/client';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import styles from './Table.module.scss';
import { calcColumnLeftOffset, mapAlignToStyle } from './helpers';
import { ColGroup } from './components/ColGroup';

interface TableProps<T> {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  onClickOnRow?: (arg: T, initiatorId: string) => void;
  className?: string;
  activeId?: string | null;
  summaryRow?: Array<ReactNode>;
  isLoading: boolean;
  emptyText?: string;
  hasMoreData: boolean;
  error?: ApolloError;
  fetchMore: () => void;
  searchComponent?: ReactElement;
}

export const Table = <T,>({
  columns,
  data,
  onClickOnRow,
  className,
  activeId,
  summaryRow,
  isLoading,
  emptyText,
  hasMoreData,
  error,
  fetchMore,
  searchComponent,
}: TableProps<T>) => {
  const { t } = useTranslation('common');

  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: undefined,
      minSize: undefined,
      maxSize: undefined,
    },
  });

  const pinnedColumnIndexes: Array<number> = [];

  const headerTableRef = useRef<HTMLTableRowElement>(null);

  const isEmpty = !data.length;

  const handleRowClick = (
    e: MouseEvent<HTMLDivElement>,
    arg: T,
    cellId: string
  ) => {
    if (onClickOnRow && !window.getSelection()?.toString()) {
      onClickOnRow(arg, cellId);
    }
  };

  const getTrClasses = (itemID: string): string =>
    classnames(
      styles.tr,
      onClickOnRow && styles.clickable,
      activeId === itemID ? styles.active : null
    );

  const loadTrigger = useInfiniteScroll(fetchMore);

  return (
    <div
      className={classnames(
        styles.fullScreenOverflowContainer,
        isEmpty && styles.grow
      )}
    >
      {searchComponent}

      <div
        className={classnames(
          styles.tableContainer,
          !!searchComponent && styles.withSearch
        )}
      >
        {isEmpty ? (
          <EmptyDataBlock
            iconSize={IconSize.Large}
            emptyDataText={emptyText || t('errors.emptyData')}
          />
        ) : (
          <table
            className={classnames(
              className,
              styles.table,
              summaryRow && styles.hasSummaryRow
            )}
            cellSpacing="0"
          >
            <ColGroup columns={tableInstance.getAllColumns()} />
            <thead className={styles.header}>
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  ref={headerTableRef}
                  className={styles.tr}
                >
                  {headerGroup.headers.map((header, index) => {
                    const { minSize, maxSize, meta, enablePinning } =
                      header.column.columnDef;

                    if (enablePinning) {
                      pinnedColumnIndexes.push(index);
                    }

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          maxWidth: maxSize || '',
                          minWidth: minSize || '',
                          left: enablePinning
                            ? calcColumnLeftOffset(index)
                            : undefined,
                        }}
                        className={classnames(
                          styles.th,
                          meta?.align && mapAlignToStyle[meta.align],
                          enablePinning && styles.sticky
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={styles.tbody}>
              {summaryRow && (
                <tr
                  data-testid="summary-row"
                  className={classnames(styles.tr, styles.summaryRow)}
                >
                  {summaryRow.map((item, index) => {
                    const isPinned = pinnedColumnIndexes.includes(index);

                    return (
                      <td
                        className={classnames(
                          styles.cell,
                          isPinned && styles.sticky
                        )}
                        style={{
                          left: isPinned
                            ? calcColumnLeftOffset(index)
                            : undefined,
                        }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={item ? `${item.toString()}${index}` : index}
                      >
                        {item}
                      </td>
                    );
                  })}
                </tr>
              )}
              {tableInstance.getRowModel().rows.map((row) => {
                const itemID = row.id;

                return (
                  <tr
                    key={row.id}
                    itemID={itemID}
                    className={getTrClasses(itemID)}
                    onClick={(e) => handleRowClick(e, row.original, itemID)}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      const { meta, enablePinning } = cell.column.columnDef;

                      return (
                        <td
                          key={cell.id}
                          style={{
                            left: enablePinning
                              ? calcColumnLeftOffset(index)
                              : undefined,
                          }}
                          className={classnames(
                            styles.td,
                            styles.cell,
                            meta?.align && mapAlignToStyle[meta.align],
                            enablePinning && styles.sticky
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {loadTrigger}
      </div>
      {!isEmpty && (
        <div className={styles.loadMoreStatusContainer}>
          <LoadMoreStatus
            isLoading={isLoading}
            hasMoreData={hasMoreData}
            error={error}
            fetchMore={fetchMore}
          />
        </div>
      )}
    </div>
  );
};
