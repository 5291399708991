import { List, ListSize } from 'ui/List';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { DropdownPosition } from 'ui/Dropdown';
import { Currency } from 'types';
import { blurActiveElement } from 'helpers/blurActiveElement';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { CurrencyAccount } from 'types/generated/gql';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { useMapLangStringToIntLanguageTag } from 'hooks/useMapLangStringToIntLanguageTag';
import { Skeleton } from 'ui/Skeleton';
import { useParams } from 'react-router';
import { AppRouteParams } from 'utils/tsUtils/extractParamsFromAppRoute';
import { useSwitchCurrentCurrencyAccount } from './hooks/useSwitchCurrentCurrencyAccount';
import {
  getDropdownStateByCurrency,
  getListItemsByCurrencies,
} from './helpers';
import styles from './CurrencyAccountDropdown.module.scss';

interface CurrencyAccountDropdownProps {
  currentCurrencyAccount?: CurrencyAccount | null;
  availableCurrencyAccounts?: Array<CurrencyAccount | null>;
  loading: boolean;
}

export const CurrencyAccountDropdown = ({
  currentCurrencyAccount,
  availableCurrencyAccounts,
  loading: availableCurrencyAccountsLoading = false,
}: CurrencyAccountDropdownProps) => {
  const { t } = useTranslation('payments');

  const dropdownButton =
    currentCurrencyAccount?.currency &&
    getDropdownStateByCurrency(currentCurrencyAccount?.currency);

  const {
    switchCurrentCurrencyAccount,
    loading: changeCurrencyPaymentLoading,
  } = useSwitchCurrentCurrencyAccount();

  const disabled =
    availableCurrencyAccountsLoading ||
    !availableCurrencyAccounts?.length ||
    !currentCurrencyAccount?.currency ||
    availableCurrencyAccounts.length === 1 ||
    changeCurrencyPaymentLoading;

  const shouldShowTooltip =
    !availableCurrencyAccountsLoading &&
    availableCurrencyAccounts?.length === 1;

  const handleItemClick = (currency?: Currency) => {
    if (currency === currentCurrencyAccount?.currency) {
      blurActiveElement();

      return;
    }

    if (currency) {
      switchCurrentCurrencyAccount(currency);
      blurActiveElement();
    }
  };

  const { lang } = useParams<AppRouteParams['Lang']['root']>();
  const intLanguageTag = useMapLangStringToIntLanguageTag(lang);

  if (availableCurrencyAccountsLoading || !dropdownButton) {
    return <Skeleton width={121} height={32} />;
  }

  return (
    <div className={styles.wrapper}>
      <Tooltip
        placement="bottom"
        tooltipTheme={TooltipTheme.Dark}
        tooltipContent={
          shouldShowTooltip ? t('accountPaymentDropdownDisabled') : undefined
        }
        shouldSetWidthFitContent={false}
      >
        <DropdownWithButton
          dropdownProps={{
            position: DropdownPosition.RightBottom,
          }}
          buttonContent={
            <div className={styles.innerButtonContent}>
              {dropdownButton.icon && dropdownButton.icon}
              {getCurrencyNameWithSymbol(dropdownButton.value)}
            </div>
          }
          buttonProps={{
            disabled,
          }}
        >
          <List
            items={getListItemsByCurrencies(
              handleItemClick,
              availableCurrencyAccounts,
              intLanguageTag
            )}
            size={ListSize.Small}
            highlightOnHover
          />
        </DropdownWithButton>
      </Tooltip>
    </div>
  );
};
