import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { useGetReferralLinkMediaItems } from '../../../queries/generated/GetReferralLinkMediaItems';
import { ReferralLinkMediaItemBasicData } from '../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';

const MEDIA_ITEMS_PER_PAGE = 10;

export const useGetReferralLinkMediaItemsQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, error, loading, isSliced } =
    useGetReferralLinkMediaItems({
      variables: {
        userId: user.id,
        first: MEDIA_ITEMS_PER_PAGE,
      },
      notifyOnNetworkStatusChange: true,
    });

  const mediaItemsConnection = data?.user.referralLinkMediaItems;

  const mediaItems: Array<ReferralLinkMediaItemBasicData> | undefined = useMemo(
    () => mediaItemsConnection?.edges.map((item) => item.node),
    [mediaItemsConnection?.edges]
  );

  const pageInfo = mediaItemsConnection?.pageInfo;

  return {
    mediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error,
    loading,
  };
};
