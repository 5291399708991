import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Select, SelectOption } from 'ui/formItems';
import { FieldName } from '../../const';

interface NextRewardCurrencySelectProps {
  onDirtyChange: (dirty: boolean) => void;
  options: Array<SelectOption>;
  disabled?: boolean;
}

export const NextRewardCurrencySelect = ({
  onDirtyChange,
  options,
  disabled,
}: NextRewardCurrencySelectProps) => {
  const { t } = useTranslation('payments');
  const { dirty } = useFormikContext();

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Select
      label={t('nextRewardCurrencyModal.currency')}
      formValidationProps={{ errorTooltipPlacement: 'right' }}
      name={FieldName.Currency}
      options={options}
      disabled={disabled}
    />
  );
};
