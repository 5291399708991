import { Form, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormBlockWrapper, Input, SelectAndInput } from 'ui/formItems';
import {
  IndentBetweenRowsSize,
  MarginBottomSize,
} from 'ui/formItems/components/FormBlockWrapper/const';
import { useContactTypeOptions } from 'hooks/useContactTypeOptions';
import { ProfileFormHeader } from '../../../../ProfileFormHeader';
import { FieldName, mapFiledNameToMaxLength } from '../const';
import { CompanyIdentitiesValues } from '../types';

interface CompanyProfileFormContentProps {
  loading: boolean;
  onDirtyChange: (dirty: boolean) => void;
}

export const CompanyProfileFormContent: FC<CompanyProfileFormContentProps> = ({
  loading,
  onDirtyChange,
}) => {
  const { t } = useTranslation('profile');
  const contactTypeOptions = useContactTypeOptions();

  const { values, isValid, dirty, errors } =
    useFormikContext<CompanyIdentitiesValues>();

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Form>
      <ProfileFormHeader isDisabled={!isValid || !dirty} isLoading={loading} />
      <FormBlockWrapper
        indentBetweenRowSize={IndentBetweenRowsSize.Large}
        marginBottomSize={MarginBottomSize.Large}
      >
        <Input name={FieldName.CompanyName} label={t('companyName')} disabled />
        <Input
          formValidationProps={{ errorTooltipPlacement: 'right' }}
          name={FieldName.Website}
          label={t('website')}
          maxLength={mapFiledNameToMaxLength[FieldName.Website]}
        />

        <SelectAndInput
          selectProps={{
            name: FieldName.ContactType,
            label: t('contactMethod'),
            options: contactTypeOptions,
          }}
          inputProps={{
            name: FieldName.ContactValue,
            label: t('contactText'),
            disabled: !values.contactType,
            maxLength: mapFiledNameToMaxLength[FieldName.ContactValue],
          }}
          isError={Boolean(errors.contactValue)}
        />
      </FormBlockWrapper>
    </Form>
  );
};
