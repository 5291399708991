import { useField } from 'formik';
import { FC } from 'react';
import { PostbackEvent } from 'types/generated/gql';
import { List } from 'ui/List';
import { Dropdown } from 'ui/Dropdown';
import { FieldName } from '../../../../../Postback/const';
import { useGetMacrosByEventQuery } from './hooks/useGetMacrosByEventQuery';
import { getListItemByMacros } from './helpers';
import styles from './MacrosDropdown.module.scss';

interface MacrosDropdownProps {
  isOpen: boolean;
  selectedPostback: PostbackEvent;
}

export const MacrosDropdown: FC<MacrosDropdownProps> = ({
  isOpen,
  selectedPostback,
}) => {
  const [field, , helpers] = useField<string>(FieldName.Url);

  const macros = useGetMacrosByEventQuery({ postbackEvent: selectedPostback });

  const handleListItemItemClick = (value?: string) => {
    if (!value) {
      return;
    }

    helpers.setValue(field.value + value);
  };

  if (!macros?.length) {
    return null;
  }

  return (
    <Dropdown isOpenForced={isOpen} withStyleMaxContent={false}>
      <List
        listContainerClassName={styles.listContainer}
        highlightOnHover
        items={getListItemByMacros(macros, handleListItemItemClick)}
      />
    </Dropdown>
  );
};
