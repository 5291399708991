import { useTranslation } from 'react-i18next';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { ListButton } from 'ui/ListButton';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/line/AltArrowRight.svg';
import {
  mapWithdrawalMethodsToIcon,
  mapWithdrawalMethodsToSubTitle,
  mapWithdrawalMethodsToTitle,
} from 'helpers/mapTypeNameToWithdrawMethodData';
import { WithdrawalMethodTypeName } from 'types';
import { GameAccountWithdrawalMethodStatus } from 'types/generated/gql';
import { GameAccountStatusLabel } from 'components/GameAccountStatusLabel';
import { useGetWithdrawalMethodsQuery } from './hooks/useGetWithdrawalMethodsQuery';
import { useLinkGameAccountModal } from './hooks/useLinkGameAccountModal';
import {
  checkIfHasGameAccount,
  mapWithdrawalStatusToLabelStatus,
} from './helpers';
import { useOpenWithdrawalMethodModal } from './hooks/useOpenWithdrawalMethodModal';
import styles from './WithdrawRewardModal.module.scss';

export const WithdrawRewardModal = () => {
  const { t } = useTranslation('payments');
  const { withdrawalMethods, loading, error } = useGetWithdrawalMethodsQuery();
  const { openLinkGameAccountModal } = useLinkGameAccountModal();
  const { handleWithdrawalMethodClick, loading: fetchWithdrawalMethodLoading } =
    useOpenWithdrawalMethodModal();

  if (loading || fetchWithdrawalMethodLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className={styles.modal}>
      <p className={styles.whereToWithdrawInfoText}>
        {t('withdrawRewardModal.whereToWithdrawInfoText')}
      </p>

      {withdrawalMethods?.map((method) => {
        if (!method) {
          return null;
        }

        return (
          <ListButton
            key={method.id}
            as="button"
            className={styles.withdrawalMethod}
            icon={mapWithdrawalMethodsToIcon[method.__typename]}
            title={mapWithdrawalMethodsToTitle[method.__typename]}
            subTitle={
              method.status === GameAccountWithdrawalMethodStatus.Confirmed
                ? method[mapWithdrawalMethodsToSubTitle[method.__typename]]
                : undefined
            }
            rightBlock={
              <div className={styles.rightBlockWrapper}>
                {method.status !==
                  GameAccountWithdrawalMethodStatus.Confirmed && (
                  <GameAccountStatusLabel
                    status={mapWithdrawalStatusToLabelStatus[method.status]}
                  />
                )}

                <ArrowRightIcon />
              </div>
            }
            onClick={() => handleWithdrawalMethodClick(method.id)}
          />
        );
      })}
      {!checkIfHasGameAccount(withdrawalMethods) && (
        <ListButton
          as="button"
          className={styles.withdrawalMethod}
          icon={
            mapWithdrawalMethodsToIcon[
              WithdrawalMethodTypeName.GameAccountWithdrawalMethod
            ]
          }
          title={t('withdrawRewardModal.linkGameAccountItem')}
          rightBlock={<ArrowRightIcon />}
          onClick={openLinkGameAccountModal}
        />
      )}
    </div>
  );
};
