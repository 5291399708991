import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { WithdrawRewardModal } from '../components/WithdrawRewardModal';

export const useWithdrawRewardModal = () => {
  const { t } = useTranslation('payments');
  const { dispatch } = useModalContext();

  const openWithdrawRewardModal = useCallback(() => {
    dispatch(
      openModal({
        title: t('withdrawRewardModal.modalTitle'),
        content: <WithdrawRewardModal />,
      })
    );
  }, [dispatch, t]);

  return {
    openWithdrawRewardModal,
  };
};
