import { FC } from 'react';
import { TopBar } from 'components/TopBar';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MediaIcon } from 'assets/icons/colors/Image.svg';
import { ReferralLinkMediaTable } from './components/ReferralLinkMediaTable';
import { useOpenModal } from './hooks/useOpenModal';

export const Media: FC = () => {
  const { t } = useTranslation(['common', 'media']);
  const { openCreateModal } = useOpenModal();

  return (
    <>
      <TopBar
        title={t('pageTitles.media')}
        icon={MediaIcon}
        action={{
          buttonText: t('media:createMedia'),
          onClick: openCreateModal,
        }}
      />
      <ReferralLinkMediaTable />
    </>
  );
};
