import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/line/AltArrowLeft.svg';
import { useLocation, useNavigate } from 'react-router';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { checkIfHasPreviousRoute } from './helpers';

export const ReturnButton: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const { createLocalizedPath } = useCreateLocalizedPath();

  const handleClick = () => {
    if (checkIfHasPreviousRoute(location)) {
      navigate(-1);
    } else {
      navigate(createLocalizedPath(AppRoute.Lang.Main));
    }
  };

  return (
    <Button
      data-testid="returnButton"
      onClick={handleClick}
      iconConfig={{ iconComponent: ArrowLeftIcon, before: true }}
      theme={ButtonTheme.BlueLight}
      size={ButtonSize.Medium}
    >
      {t('returnButton')}
    </Button>
  );
};
