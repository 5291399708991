import { t } from 'i18n';
import { ReferralStatus } from 'types/generated/gql';
import { CpaReferralLinkStatisticConnectionData } from './query/fragments/generated/CpaReferralLinkStatisticConnectionData';
import { CpaReferralLinkStatisticItemData } from './query/fragments/generated/CpaReferralLinkStatisticItemData';

export const extractDetailedStatisticItems = (
  cpaStatisticDetailedConnectionData?: CpaReferralLinkStatisticConnectionData
): Array<CpaReferralLinkStatisticItemData> | undefined =>
  cpaStatisticDetailedConnectionData?.edges.map((item) => item.node);

export const mapReferralStatusToString: Record<ReferralStatus, string> = {
  [ReferralStatus.Approved]: t('statistic:referralStatus.approved'),
  [ReferralStatus.CpaPaid]: t('statistic:referralStatus.cpaPaid'),
  [ReferralStatus.CpaUnpaid]: t('statistic:referralStatus.cpaUnpaid'),
  [ReferralStatus.Hold]: t('statistic:referralStatus.hold'),
  [ReferralStatus.Qualified]: t('statistic:referralStatus.qualified'),
  [ReferralStatus.Rejected]: t('statistic:referralStatus.rejected'),
  [ReferralStatus.Canceled]: t('statistic:referralStatus.canceled'),
};
