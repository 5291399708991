import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorRetry } from 'components/ErrorRetry';
import { Currency } from 'types';

interface NextRewardCardBottomContentProps {
  currency?: Currency;
  loading: boolean;
  isError: boolean;
  onRefetch: () => void;
}

export const NextRewardCardBottomContent: FC<
  NextRewardCardBottomContentProps
> = ({ currency, loading, isError, onRefetch }) => {
  const { t } = useTranslation('payments');

  if (loading) {
    return t('loading');
  }

  if (!currency || isError) {
    return <ErrorRetry onRefetch={onRefetch} />;
  }

  return t('nextRewardSubtitle', {
    currency,
  });
};
