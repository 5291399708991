export enum UserPrivilege {
  ViewCompanyAvailableCurrencyAccounts = 'view_company_available_currency_accounts',
  ViewCompanyPartners = 'view_company_partners',
  ViewCompanyCpaStatistic = 'view_company_cpa_statistic',
  ViewCompanyCurrentCurrencyAccount = 'view_company_current_currency_account',
  ViewCompanyMediaCampaign = 'view_company_media_campaign',
  ViewCompanyMediaItem = 'view_company_media_item',
  ViewCompanyNextRewardInfo = 'view_company_next_reward_info',
  ViewCompanyReferralLinkMediaItems = 'view_company_referral_link_media_items',
  ViewCompanyRevShareStatistic = 'view_company_rev_share_statistic',
  ViewCompanyRewardHistory = 'view_company_reward_history',
  ViewCompanyTariff = 'view_company_tariff',
  ViewCompanyTariffs = 'view_company_tariffs',
  ViewCompanyTariffsForMediaItemCreation = 'view_company_tariffs_for_media_item_creation',
  ViewCompanyWithdrawalInvoices = 'view_company_withdrawal_invoices',
  ViewCompanyWithdrawalMethod = 'view_company_withdrawal_method',
  ViewCompanyWithdrawalMethods = 'view_company_withdrawal_methods',
  ActivateCompany = 'activate_company',
  ActivatePartner = 'activate_partner',
  CreateApplicationToAddPartners = 'create_application_to_add_partners',
  CreateCompanyReferralLinkMediaItem = 'create_company_referral_link_media_item',
  CreateGameAccountLink = 'create_game_account_link',
  CreatePartnerReferralLinkMediaItem = 'create_partner_referral_link_media_item',
  CreatePostback = 'create_postback',
  DeletePostback = 'delete_postback',
  ReapplyCompanyApplication = 'reapply_company_application',
  ReapplyPartnerApplication = 'reapply_partner_application',
  SwitchCompanyCurrentCurrencyAccount = 'switch_company_current_currency_account',
  SwitchCompanyNextRewardCurrency = 'switch_company_next_reward_currency',
  SwitchPartnerCurrentCurrencyAccount = 'switch_partner_current_currency_account',
  SwitchPartnerNextRewardCurrency = 'switch_partner_next_reward_currency',
  UpdateCompanyIdentities = 'update_company_identities',
  UpdatePartnerIdentities = 'update_partner_identities',
  UpdatePostback = 'update_postback',
  WithdrawToGameAccountFromCurrentCurrencyAccount = 'withdraw_to_game_account_from_current_currency_account',
  ViewPartnerAvailableCurrencyAccounts = 'view_partner_available_currency_accounts',
  ViewPartnerCpaStatistic = 'view_partner_cpa_statistic',
  ViewPartnerCurrentCurrencyAccount = 'view_partner_current_currency_account',
  ViewPartnerMediaCampaign = 'view_partner_media_campaign',
  ViewPartnerMediaItem = 'view_partner_media_item',
  ViewPartnerNextRewardInfo = 'view_partner_next_reward_info',
  ViewPartnerReferralLinkMediaItems = 'view_partner_referral_link_media_items',
  ViewPartnerRevShareStatistic = 'view_partner_rev_share_statistic',
  ViewPartnerRewardHistory = 'view_partner_reward_history',
  ViewPartnerTariff = 'view_partner_tariff',
  ViewPartnerTariffs = 'view_partner_tariffs',
  ViewPartnerTariffsForMediaItemCreation = 'view_partner_tariffs_for_media_item_creation',
  ViewPartnerWithdrawalInvoices = 'view_partner_withdrawal_invoices',
  ViewPartnerWithdrawalMethod = 'view_partner_withdrawal_method',
  ViewPartnerWithdrawalMethods = 'view_partner_withdrawal_methods',
  ViewCurrentRewardPeriod = 'view_current_reward_period',
  ViewPostbackMacrosByPostbackEvent = 'view_postback_macros_by_postback_event',
  ViewPreviousRewardPeriod = 'view_previous_reward_period',
  ViewUser = 'view_user',
  ViewReferralLinkMediaItemStatistic = 'view_referral_link_media_item_statistic',
  ViewReferralLinkMediaItemRevShareStatistic = 'view_referral_link_media_item_rev_share_statistic',
}
