import { Dispatch, FC, SetStateAction } from 'react';
import { TextWithLink } from 'ui/TextWithLink';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import { useTranslation } from 'react-i18next';
import { PasswordResetForm } from '../PasswordResetForm';
import styles from './PasswordResetFormWrapper.module.scss';

interface PasswordResetFormWrapperProps {
  setEmailSent: Dispatch<SetStateAction<boolean>>;
}

export const PasswordResetFormWrapper: FC<PasswordResetFormWrapperProps> = ({
  setEmailSent,
}) => {
  const { t } = useTranslation('auth');
  const { createLocalizedPath } = useCreateLocalizedPath();

  return (
    <div>
      <div className={styles.contentContainer}>
        <h1 className={SPEC_HEADER_LARGE}>{t('passwordReset.header')}</h1>
        <p className={styles.description}>{t('passwordReset.description')}</p>
        <PasswordResetForm setEmailSent={setEmailSent} />
      </div>
      <TextWithLink
        text={t('passwordReset.problem')}
        linkText={t('passwordReset.support')}
        to={createLocalizedPath(AppRoute.Lang.Support)}
      />
    </div>
  );
};
