import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { DateFormat, formatDate } from 'utils/formatDate';
import { TableCellAlign } from 'ui/Table';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { TransactionStatusLabel } from 'components/TransactionStatusLabel';
import {
  mapWithdrawalMethodsToIcon,
  mapWithdrawalMethodsToTitle,
} from 'helpers/mapTypeNameToWithdrawMethodData';
import { WithdrawalInvoiceData } from '../queries/fragments/generated/WithdrawalInvoiceData';
import { mapGameAccountWithdrawalInvoiceStatusToLabelType } from '../helpers';

const COLUMNS_SIZES = {
  methodMinSize: 200,
  dateSize: 200,
  statusSize: 200,
  amountSize: 160,
  paidAmountSize: 160,
} as const;

export const useWithdrawalInvoicesColumns = (): Array<
  ColumnDef<WithdrawalInvoiceData>
> => {
  const { t } = useTranslation(['common', 'payments']);
  const columnHelper = createColumnHelper<WithdrawalInvoiceData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<WithdrawalInvoiceData, any>> = [
    columnHelper.accessor('method', {
      header: t('payments:withdrawalInvoicesTable.method'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          icon={mapWithdrawalMethodsToIcon[row.original.method.__typename]}
          title={mapWithdrawalMethodsToTitle[row.original.method.__typename]}
          subTitle={row.original.method.gameAccountNumber}
        />
      ),
      minSize: COLUMNS_SIZES.methodMinSize,
    }),
    columnHelper.accessor('date', {
      header: t('date'),
      cell: ({ row }) =>
        formatDate(
          new Date(row.original.date),
          DateFormat.FullDateWithoutHours
        ),
      size: COLUMNS_SIZES.dateSize,
    }),
    columnHelper.accessor('status', {
      header: t('status'),
      cell: ({ row }) => (
        <TransactionStatusLabel
          type={
            mapGameAccountWithdrawalInvoiceStatusToLabelType[
              row.original.status
            ]
          }
        />
      ),
      size: COLUMNS_SIZES.statusSize,
    }),
    columnHelper.accessor('amount', {
      header: t('payments:withdrawalInvoicesTable.amount'),
      cell: ({ row }) => getMoneyAmount(row.original.amount),
      meta: { align: TableCellAlign.Right },
      size: COLUMNS_SIZES.amountSize,
    }),
    columnHelper.accessor('paidAmount', {
      header: t('payments:withdrawalInvoicesTable.paidAmount'),
      cell: ({ row }) => (
        <MayBeNullCell
          value={
            row.original.paidAmount
              ? getMoneyAmount(row.original.paidAmount)
              : null
          }
        />
      ),
      meta: { align: TableCellAlign.Right },
      size: COLUMNS_SIZES.paidAmountSize,
    }),
  ];

  return columns;
};
