import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import { PartnerCpaStatisticItemData } from '../queries/fragments/generated/PartnerCpaStatisticItemData';
import { COLUMNS_SIZES } from '../../../const';

export const usePartnerCpaStatisticItemsColumns = (): Array<
  ColumnDef<PartnerCpaStatisticItemData>
> => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<PartnerCpaStatisticItemData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<PartnerCpaStatisticItemData, any>> = [
    columnHelper.accessor('id', {
      header: t('statistic:table.mediaName'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.name}
          subTitle={
            <CopyLink
              tooltipText={t('linkCopied')}
              to={row.original.link}
              target="_blank"
            >
              {row.original.link}
            </CopyLink>
          }
        />
      ),
      size: COLUMNS_SIZES.mediaNameSize,
      enablePinning: true,
    }),
    columnHelper.accessor('statisticInfo.hits', {
      header: t('statistic:table.hits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.hosts', {
      header: t('statistic:table.hosts'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.registrations', {
      header: t('statistic:table.registrations'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.firstDeposits', {
      header: t('statistic:table.firstDeposits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      header: t('statistic:table.depositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.hold', {
      header: t('statistic:table.hold'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.qualified', {
      header: t('statistic:table.qualified'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.approved', {
      header: t('statistic:table.approved'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.paid', {
      header: t('statistic:table.paid'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.rejected', {
      header: t('statistic:table.rejected'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.reward', {
      header: t('statistic:table.reward'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      header: t('statistic:table.averageDeposit'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      header: t('statistic:table.redepositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
      header: t('statistic:table.conversionHostsToRegPercent'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
      header: t('statistic:table.conversionRegToDepPercent'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      header: t('statistic:table.numberOfRedeposits'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      header: t('statistic:table.withdrawalsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
  ];

  return columns;
};
