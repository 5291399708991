import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { SubHeaderBlock } from 'components/SubHeaderBlock';

interface ProfileFormHeaderProps {
  isLoading: boolean;
  isDisabled: boolean;
}

export const ProfileFormHeader: FC<ProfileFormHeaderProps> = ({
  isLoading,
  isDisabled,
}) => {
  const { t } = useTranslation(['common', 'profile']);

  return (
    <SubHeaderBlock>
      <h2>{t('profile:profileIdentities')}</h2>
      <Button
        theme={ButtonTheme.BlueGhost}
        disabled={isDisabled}
        size={ButtonSize.Small}
        isLoading={isLoading}
        type="submit"
      >
        {t('saveButton')}
      </Button>
    </SubHeaderBlock>
  );
};
