import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useWithdrawToGameAccountFromCurrentCurrencyAccount } from '../queries/generated/WithdrawToGameAccountFromCurrentCurrencyAccount';

interface UseWithdrawToGameAccountMutationParams {
  onWithdrawalCreated: () => void;
}

export const useWithdrawToGameAccountFromCurrentCurrencyAccountMutation = ({
  onWithdrawalCreated,
}: UseWithdrawToGameAccountMutationParams) => {
  const { t } = useTranslation(['payments', 'common']);

  const handleCompleted = () => {
    onWithdrawalCreated();
    toast.success(t('confirmedGameAccountModal.alertSuccessWithdrawal'));
  };

  const handleError = () => {
    toast.error(t('common:errors.commonError'));
  };

  const [withdrawToGameAccount, { loading }] =
    useWithdrawToGameAccountFromCurrentCurrencyAccount({
      onCompleted: handleCompleted,
      onError: handleError,
    });

  return { withdrawToGameAccount, loading };
};
