import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { NonNullableDateRange } from 'types';
import { CompanyRevShareStatisticInfoData } from '../queries/fragments/generated/CompanyRevShareStatisticInfoData';
import { useGetCompanyRevShareStatisticTotalQuery } from './useGetCompanyRevShareStatisticTotalQuery';

type GetSummaryRowData = (
  info: CompanyRevShareStatisticInfoData
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = ({
  hits,
  hosts,
  registrations,
  firstDeposits,
  depositsAll,
  withdrawalsAll,
  depositsFee,
  withdrawalsFee,
  adminFee,
  reward,
  averageDeposit,
  redepositsAll,
  conversionHostsToRegPercent,
  conversionRegToDepPercent,
  numberOfRedeposits,
}) => {
  const result: Array<ReactNode> = [
    undefined,
    hits,
    hosts,
    registrations,
    firstDeposits,
    getMoneyAmount(depositsAll),
    getMoneyAmount(withdrawalsAll),
    getMoneyAmount(depositsFee),
    getMoneyAmount(withdrawalsFee),
    getMoneyAmount(adminFee),
    getMoneyAmount(reward),
    getMoneyAmount(averageDeposit),
    getMoneyAmount(redepositsAll),
    `${conversionHostsToRegPercent}%`,
    `${conversionRegToDepPercent}%`,
    numberOfRedeposits,
  ];

  return result;
};

export const useGenerateCompanyRevShareStatisticSummaryRow = (
  nonNullableDateRange: NonNullableDateRange
) => {
  const { t } = useTranslation('statistic');

  const { totalStatistic, error, loading } =
    useGetCompanyRevShareStatisticTotalQuery(nonNullableDateRange);

  const summaryRowData = totalStatistic
    ? [t('table.allMedia'), ...getSummaryRowData(totalStatistic)]
    : undefined;

  return { summaryRowData, loading, error };
};
