import { ApolloError } from '@apollo/client';
import { useAuth } from 'components/auth';
import { ErrorCode } from 'const';
import { FormikErrors, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { checkIsApolloErrorHasErrorCode } from 'utils/checkIsApolloErrorHasErrorCode';

export const useRegisterMutationHandlers = <
  TRegistrationValues extends {
    email: string;
    name: string;
    createPassword: string;
  },
>() => {
  const { t } = useTranslation(['common', 'auth']);
  const auth = useAuth();

  const handleCompleted = async (values: TRegistrationValues) => {
    try {
      await auth.logIn(values.email, values.createPassword);
    } catch {
      toast.error(t('errors.commonError'));
    }
  };

  const handleError = (
    err: ApolloError,
    actions: FormikHelpers<TRegistrationValues>
  ) => {
    if (checkIsApolloErrorHasErrorCode(err, ErrorCode.NotUnique)) {
      actions.setErrors({
        email: t('auth:registration.formCommonFields.notUniqueError'),
        name: t('auth:registration.formCommonFields.notUniqueError'),
      } as FormikErrors<TRegistrationValues>);
    } else {
      toast.error(t('errors.commonError'));
    }
  };

  return {
    handleCompleted,
    handleError,
  };
};
