import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'ui/formItems';
import { useCurrencySelectOptions } from 'hooks/useCurrencySelectOptions';

interface CurrencySelectProps {
  name: string;
}

export const CurrencySelect: FC<CurrencySelectProps> = ({ name }) => {
  const { t } = useTranslation('auth');
  const { currencySelectOptions, error, loading } = useCurrencySelectOptions();

  return (
    <Select
      isLoadingError={!!error}
      isLoading={loading}
      label={t('registration.formCommonFields.currency')}
      formValidationProps={{ errorTooltipPlacement: 'right' }}
      name={name}
      options={currencySelectOptions}
    />
  );
};
