import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/Button';
import classnames from 'classnames';
import { useNavigate } from 'react-router';
import { addBasePath } from 'utils/routing/addBasePath';
import { useLanguage } from 'contexts/LanguageContext';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import styles from './DeclinedBlock.module.scss';

interface DeclinedBlockProps {
  declineReason?: string | null;
}

export const DeclinedBlock: FC<DeclinedBlockProps> = ({ declineReason }) => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const { currentLanguage } = useLanguage();

  const handleButtonClick = () => {
    navigate({
      pathname: addBasePath(AppRoute.Lang.Reapplication, currentLanguage),
    });
  };

  return (
    <div data-testid="declined-block" className={styles.contentContainer}>
      <h1
        className={classnames(
          SPEC_HEADER_LARGE,
          !declineReason && styles.noReason
        )}
      >
        {t('application.declinedHeader')}
      </h1>
      {declineReason && <p className={styles.declineReason}>{declineReason}</p>}
      <Button isFullWidth onClick={handleButtonClick}>
        {t('application.reapply')}
      </Button>
    </div>
  );
};
