import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { NonNullableDateRange } from 'types';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { useGetRevShareStatisticDetailedQuery } from './hooks/useGetRevShareStatisticDetailedQuery';
import { useRevShareStatisticDetailedColumns } from './hooks/useRevShareStatisticDetailedColumns';
import { extractDetailedStatisticItems } from './helpers';
import { useGenerateRevShareStatisticDetailedSummaryRow } from './hooks/useGenerateRevShareStatisticDetailedSummaryRow';
import { RevShareReferralLinkStatisticItemData } from './query/fragments/generated/RevShareReferralLinkStatisticItemData';

interface RevShareStatisticDetailedProps {
  revShareMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const RevShareStatisticDetailed: FC<RevShareStatisticDetailedProps> = ({
  revShareMediaItemId,
  nonNullableDateRange,
}) => {
  const { t } = useTranslation('statistic');

  const {
    revShareStatisticDetailed,
    pageInfo,
    fetchMore,
    isSliced,
    loading: itemsQueryLoading,
    error: itemsQueryError,
  } = useGetRevShareStatisticDetailedQuery({
    revShareMediaItemId,
    nonNullableDateRange,
  });

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGenerateRevShareStatisticDetailedSummaryRow({
    revShareMediaItemId,
    nonNullableDateRange,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = useRevShareStatisticDetailedColumns({
    link: revShareStatisticDetailed?.link,
  });

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const tableData: Array<RevShareReferralLinkStatisticItemData> | undefined =
    extractDetailedStatisticItems(
      revShareStatisticDetailed?.revShareStatistic.statisticItems
    );

  if ((!tableData || !summaryRowData) && loading) {
    return <Loader />;
  }

  if (error || !tableData || !summaryRowData?.length) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={tableData}
      summaryRow={summaryRowData}
      emptyText={t('emptyData')}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
