import { useTranslation } from 'react-i18next';
import { Input } from 'ui/formItems';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { FieldName } from './const';
import styles from './DeletedGameAccountModal.module.scss';
import { ACCOUNT_NUMBER_MAX_LENGTH } from '../WithdrawRewardModal/const';

interface PendingGameAccountModalProps {
  accountNumber: string;
}

export const DeletedGameAccountModal: FC<PendingGameAccountModalProps> = ({
  accountNumber,
}) => {
  const { t } = useTranslation('payments');

  return (
    <>
      <div className={styles.description}>
        <p>{t('deletedGameAccountModal.modalText')}</p>
      </div>
      <Formik
        initialValues={{
          [FieldName.AccountNumber]: accountNumber,
        }}
        onSubmit={() => undefined}
      >
        <Form>
          <Input
            name={FieldName.AccountNumber}
            label={t('deletedGameAccountModal.accountNumber')}
            maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
            disabled
          />
        </Form>
      </Formik>
    </>
  );
};
