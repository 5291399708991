import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorRetry } from 'components/ErrorRetry';
import { CurrencyAccount } from 'types/generated/gql';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { useParams } from 'react-router';
import { AppRouteParams } from 'utils/tsUtils/extractParamsFromAppRoute';
import { useMapLangStringToIntLanguageTag } from 'hooks/useMapLangStringToIntLanguageTag';

interface BalanceCardBottomContentProps {
  currentCurrencyAccount?: CurrencyAccount | null;
  availableCurrencyAccounts?: Array<CurrencyAccount | null>;
  loading: boolean;
  isError: boolean;
  onRefetch: () => void;
}

export const BalanceCardBottomContent: FC<BalanceCardBottomContentProps> = ({
  currentCurrencyAccount,
  availableCurrencyAccounts,
  loading,
  isError,
  onRefetch,
}) => {
  const { t } = useTranslation('payments');
  const { lang } = useParams<AppRouteParams['Lang']['root']>();

  const intLanguageTag = useMapLangStringToIntLanguageTag(lang);

  const isCurrentCurrencyAccountInvalid =
    !currentCurrencyAccount?.currency ||
    currentCurrencyAccount.amount === null ||
    !availableCurrencyAccounts ||
    isError;

  if (loading) {
    return t('loading');
  }

  if (isCurrentCurrencyAccountInvalid) {
    return <ErrorRetry onRefetch={onRefetch} />;
  }

  return getCurrencyDisplayName(
    currentCurrencyAccount.currency,
    intLanguageTag
  );
};
