import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useDropdownTrigger } from './hooks/useDropdownTrigger';
import { DropdownContainer } from './components/DropdownContainer';
import { DropdownPosition } from './const';
import styles from './Dropdown.module.scss';

export interface DropdownProps {
  children: ReactNode;
  isOpenForced?: boolean;
  addStyleToContainer?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  trigger?: ReactNode;
  position?: DropdownPosition;
  withStyleMaxContent?: boolean;
  hasGrayOutline?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  isOpenForced,
  addStyleToContainer = true,
  onOpenChange,
  trigger,
  position,
  withStyleMaxContent = true,
  hasGrayOutline,
}) => {
  const { isOpen, handleClick, handleBlur } = useDropdownTrigger({
    isOpenForced,
    onOpenChange,
  });

  return (
    <div
      className={classNames(
        styles.container,
        withStyleMaxContent && styles.withStyleMaxContent
      )}
      onBlur={handleBlur}
    >
      {trigger && <div onClick={handleClick}>{trigger}</div>}
      {isOpen && (
        <DropdownContainer
          position={position}
          withStyleMaxContent={withStyleMaxContent}
          addStyleToContainer={addStyleToContainer}
          hasGrayOutline={hasGrayOutline}
        >
          {children}
        </DropdownContainer>
      )}
    </div>
  );
};
