import { TariffTypeName } from 'types';
import { ReactElement } from 'react';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { IconSize } from 'ui/Icon';
import { PaymentModel } from 'components/PaymentModel';
import { CountriesList } from 'components/CountriesList';
import { DateFormat, formatDate } from 'utils/formatDate';
import { t } from 'i18n';
import { TariffData } from '../../../../../queries/generated/TariffData';

interface TariffItemRow {
  title: string;
  value: ReactElement | string;
}

export const getTariffItemData = (tariff: TariffData): Array<TariffItemRow> => {
  let tariffData: Array<TariffItemRow> = [];

  if (tariff.__typename === TariffTypeName.RevShareTariff) {
    const { name, rewardPercent, createdAt } = tariff;

    tariffData = [
      {
        title: t('tariffs:name'),
        value: name,
      },
      {
        title: t('tariffs:paymentModel'),
        value: (
          <PaymentModel
            tariffTypeName={tariff.__typename}
            iconSize={IconSize.Large}
          />
        ),
      },
      {
        title: t('tariffs:rewardPercent'),
        value: `${rewardPercent} %`,
      },
      {
        title: t('createdAt'),
        value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutYear),
      },
    ];
  } else if (tariff.__typename === TariffTypeName.CpaTariff) {
    const { name, reward, baseline, geolocation, createdAt } = tariff;

    tariffData = [
      {
        title: t('tariffs:name'),
        value: name,
      },
      {
        title: t('tariffs:paymentModel'),
        value: (
          <PaymentModel
            tariffTypeName={tariff.__typename}
            iconSize={IconSize.Large}
          />
        ),
      },
      {
        title: t('tariffs:reward'),
        value: getMoneyAmount(reward),
      },
      {
        title: t('tariffs.baseline'),
        value: getMoneyAmount(baseline),
      },
      {
        title: t('createdAt'),
        value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutYear),
      },
      {
        title: t('tariffs:geolocation'),
        value: <CountriesList countries={geolocation} />,
      },
    ];
  }

  return tariffData;
};
