import {
  FocusEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';

interface UseDropdownTriggerParams {
  isOpenForced?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const useDropdownTrigger = ({
  isOpenForced,
  onOpenChange,
}: UseDropdownTriggerParams) => {
  const [isOpenInternal, setIsOpenInternal] = useState(false);

  const isOpen = isOpenForced === undefined ? isOpenInternal : isOpenForced;

  const handleClick: MouseEventHandler<HTMLElement> = () => {
    if (!isOpenForced) {
      setIsOpenInternal((prev) => !prev);
    }
  };

  const handleBlur: FocusEventHandler<HTMLElement> = (e) => {
    if (isOpenForced || e.currentTarget.contains(e.relatedTarget)) {
      return;
    }

    setIsOpenInternal(false);
  };

  useEffect(() => {
    onOpenChange?.(isOpen);
  }, [isOpen, onOpenChange]);

  return { isOpen, handleClick, handleBlur };
};
