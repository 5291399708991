import { ListItem } from 'ui/List';
import { Currency } from 'types';
import { CurrencyAccount } from 'types/generated/gql';
import { FlagIcon } from 'ui/FlagIcon';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';

export const getListItemsByCurrencies = (
  onClick: (value?: Currency) => void,
  availableCurrencyAccounts?: Array<CurrencyAccount | null>,
  locale?: string
): Array<ListItem<Currency>> => {
  if (!availableCurrencyAccounts?.length) {
    return [];
  }

  const filteredCurrencyAccounts = availableCurrencyAccounts.filter(
    (account): account is CurrencyAccount => account !== null
  );

  return filteredCurrencyAccounts.map(({ currency }) => ({
    label: getCurrencyDisplayName(currency, locale),
    labelRight: getCurrencyNameWithSymbol(currency),
    leftElement: <FlagIcon code={currency} />,
    value: currency,
    onClick,
  }));
};
