import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { ReturnButton } from 'components/ReturnButton';
import { SupportButtons } from 'components/SupportButtons';
import { VerticalLayout } from 'ui/VerticalLayout';
import { Footer } from 'components/Footer';
import styles from './Support.module.scss';

export const Support: FC = () => {
  const { t } = useTranslation();

  const centralBlockContent = (
    <>
      <div className={styles.contentContainer}>
        <h1 className={SPEC_HEADER_LARGE}>{t('support.header')}</h1>
        <p>{t('support.description')}</p>
      </div>
      <SupportButtons />
    </>
  );

  return (
    <VerticalLayout
      topBlock={<ReturnButton />}
      centralBlock={centralBlockContent}
      bottomBlock={<Footer showManagerSupportLink />}
    />
  );
};
