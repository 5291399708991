import { FC, useState } from 'react';
import { VerticalLayout } from 'ui/VerticalLayout';
import { ReturnButton } from 'components/ReturnButton';
import { Footer } from 'components/Footer';
import { PasswordResetBlock } from './components/PasswordResetBlock';

export const PasswordReset: FC = () => {
  const [hasEmailSent, setEmailSent] = useState(false);

  return (
    <VerticalLayout
      topBlock={!hasEmailSent && <ReturnButton />}
      centralBlock={
        <PasswordResetBlock
          hasEmailSent={hasEmailSent}
          setEmailSent={setEmailSent}
        />
      }
      bottomBlock={<Footer />}
    />
  );
};
