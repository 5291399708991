import { Column } from '@tanstack/react-table';
import { ReactNode } from 'react';

interface ColGroupProps<T> {
  columns: Array<Column<T, unknown>>;
}

export const ColGroup = <T,>({ columns }: ColGroupProps<T>): ReactNode => (
  <colgroup>
    {columns.map(({ columnDef, id }) => {
      const { minSize, maxSize, size } = columnDef;

      return (
        <col
          key={id}
          style={{
            width: size,
            minWidth: minSize || '',
            maxWidth: maxSize || '',
          }}
        />
      );
    })}
  </colgroup>
);
