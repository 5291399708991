import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'ui/Tabs';
import { RewardHistoryTable } from './components/RewardHistoryTable';
import { WithdrawalInvoicesTable } from './components/WithdrawalInvoicesTable';
import styles from './PaymentDataBlock.module.scss';

export const PaymentDataBlock: FC = () => {
  const { t } = useTranslation(['common', 'payments']);

  const tabs = [
    {
      title: t('payments:tabs.rewards'),
      content: <RewardHistoryTable />,
    },
    {
      title: t('payments:tabs.withdrawals'),
      content: <WithdrawalInvoicesTable />,
    },
  ];

  return <Tabs tabs={tabs} tabsControlsClass={styles.tabs} />;
};
