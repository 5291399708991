import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { VerticalLayout } from 'ui/VerticalLayout';
import { ReturnButton } from 'components/ReturnButton';
import { Footer } from 'components/Footer';
import { RegistrationCompanyForm } from './components/RegistrationCompanyForm';
import styles from './RegistrationCompany.module.scss';

export const RegistrationCompany: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <VerticalLayout
      topBlock={<ReturnButton />}
      centralBlock={
        <>
          <div className={styles.textBlock}>
            <h1 className={SPEC_HEADER_LARGE}>
              {t('registration.company.header')}
            </h1>
            <p>{t('registration.subRegistrationPagesDescription')}</p>
          </div>
          <RegistrationCompanyForm />
        </>
      }
      bottomBlock={<Footer isRegistrationPage />}
    />
  );
};
