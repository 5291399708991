import { t } from 'i18n';
import { StatusLabelSize, StatusLabelType } from 'ui/StatusLabel';
import {
  GameAccountStatusLabelSize,
  GameAccountStatusLabelType,
} from './const';

export const mapWithdrawalStatusToStatusLabelType: Record<
  GameAccountStatusLabelType,
  StatusLabelType
> = {
  [GameAccountStatusLabelType.Pending]: StatusLabelType.Pending,
  [GameAccountStatusLabelType.Deleted]: StatusLabelType.Warning,
};

export const mapWithdrawalStatusToText: Record<
  GameAccountStatusLabelType,
  string
> = {
  [GameAccountStatusLabelType.Pending]: t(
    'payments:gameAccountStatuses.pending'
  ),
  [GameAccountStatusLabelType.Deleted]: t(
    'payments:gameAccountStatuses.deleted'
  ),
};

export const mapSizeToStatusLabelSize: Record<
  GameAccountStatusLabelSize,
  StatusLabelSize
> = {
  [GameAccountStatusLabelSize.Large]: StatusLabelSize.Large,
  [GameAccountStatusLabelSize.Small]: StatusLabelSize.Small,
};
