import { NavLinksList, NavLinksListItem } from 'components/NavLinksList';
import { AppRoute } from 'const';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const StatisticNavLinksList: FC = () => {
  const { t } = useTranslation('statistic');
  const [searchParams] = useSearchParams();
  const { createLocalizedPath } = useCreateLocalizedPath();

  const navLinksListItems: Array<NavLinksListItem> = [
    {
      title: t('linksTitles.revShare'),
      path: createLocalizedPath(
        composeRoutes(
          [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.RevShare.root],
          { searchParams }
        )
      ),
    },
    {
      title: t('linksTitles.cpa'),
      path: createLocalizedPath(
        composeRoutes(
          [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.Cpa.root],
          { searchParams }
        )
      ),
    },
  ];

  return <NavLinksList items={navLinksListItems} />;
};
